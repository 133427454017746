/* istanbul ignore file */
import {CustomSettings2Fragment} from '../../gql/graphql';

export class CustomSettings2Model {
  public hideGiftCard: boolean;
  public hideCoupon: boolean;
  public disableManualPayment: boolean;
  public hidePolicyAgreementCheckbox: boolean;

  constructor(customSettings?: CustomSettings2Fragment | null) {
    this.hideGiftCard = customSettings?.lockGiftCard ?? false;
    this.hideCoupon = customSettings?.lockCouponCode ?? false;
    this.disableManualPayment = customSettings?.disabledManualPayment ?? false;
    this.hidePolicyAgreementCheckbox = customSettings?.disabledPolicyAgreementCheckbox ?? false;
  }
}
